<template>
  <div>
    <div class="text-h5 my-5">
      {{ $t('Licenses') }}
    </div>
    <HdToggleField
      v-for="app in ac.items"
      :key="app.appId"
      :model="app"
      @valuechange="onValueChange"
    />
  </div>
</template>
<script>
import HdToggleField from './field/HdToggleField'
import { ApplicationCollection } from '../../model/application/ApplicationCollection'

export default {
  name: 'ApplicationToggleList',
  components: {
    HdToggleField
  },  
  props: {
    selectedApplications: {
      type: Array,
      required: false,
      default(){
        return []
      } 
    }
  },

  data() {
    const ac = new ApplicationCollection(this.$store.state.user.applications.map(a => ({
      ...a,
      dependencies: [],
      isSelected: false
    })))
    return {
      ac
    }
  },

  watch: {
    selectedApplications: {
      deep: true,
      handler() {
        const baseItems = this.$store.state.user.applications.map((a) => ({
          ...a,
          dependencies: [],
          isSelected: false
        }))

        if (this.selectedApplications.length) {
          this.ac.setItems(baseItems.map(item => ({
            ...item,
            isSelected: this.selectedApplications.some((e) => e.appId === item.appId)
          })))
        } else {
          this.ac.setItems(baseItems)
        }
      }
    }
  },

  created() {
    // Initialize with applications from store
    this.ac.setItems(this.$store.state.user.applications.map(a => ({
      ...a,
      dependencies: [],
      isSelected: false
    })))
  },

  methods: {
    onValueChange(val) {
      this.ac.handleDependencies(val)
    },
    getValues() {
      return this.ac.getEnabledApplications()
    }
  },
}
</script>
